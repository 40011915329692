/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo,useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { Modal, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHistory } from "react-router-dom";
import { FaUserCircle ,FaCalendarAlt, FaRegCopy} from "react-icons/fa";
import { IoCloseSharp } from 'react-icons/io5';
// import { BsLightningFill } from "react-icons/bs";
import { NODE_API_URL } from "../../../../../env_config";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
// import { green } from '@mui/material/colors'
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import axios from "axios";
// import {IUD_SHORTLIST} from "./../../../../env_config"
// import {DETAILPAGE_LOG} from "./../../../../env_config"
// import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
// import { StoreDiamondDB } from "../../../_helpers/IndexedDB";
// import { GetDiamondDB } from "../../../_helpers/IndexedDB";
import { SwalWarn } from "../../../Popup";
import { ImagePopupCommon } from "../ImagePopupCommon";
import { VideoPopupCommon } from "../VideoPopupCommon";
import { LazyImageComponent } from "../LazyImageComponent";
import { LiaAwardSolid } from "react-icons/lia";
import { CopyUrlAlert } from "../../../Popup/CopyUrlAlert";
import CertificateLink from "../../../CertificateLink";
export const RecentDiamondCard = (props) => {
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------
    const DetailSemiAprrove = () => {
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    function handleClose(event, reason) {
        setOpen(false);
        setSuccessOpen(false);
    }
   
    function handelDetails(row){
        const url = `/detail/${row.diamondType === "N"  ? "N" : "L"}-${row.stockId}`;
        window.open(url, '_blank');
    }
    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const RemoveRecently = (row) => {
        // console.log(row,"row")
        const finalData = {
            "id":[row.stockId]
        }
        axios({
            method: "DELETE",
            url: `${NODE_API_URL}/AUS/removeRecentlyViewDiamond`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            // console.log(res,"RESPONSE")
            props.setUpdateRecently(res)
        }).catch(err => {
            // alert(err.response.data.msg)

        })
    }
    return (
        <>
            <div
                className={`mb-5 px-2 grid_box ${props.diamondType === "N" ? "natTab" : "labTab"}`}
                key={props.stockId}
                id={props.stockId}
            >
                <div className="row m-0 card gridData ribbon ribbon-top ribbon-left">
                    {props.diamondType === "N" || props.diamondType === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                   
                    { (props.diamondType === "L" ) && (props.growthType && props.growthType === "HPHT")  ? 
                    <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"180px",left:"-3px"}}>
                        HPHT
                    </div> : null }
                    { (props.diamondType === "L" ) && (props.growthType && props.growthType === "CVD")  ? 
                    <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"180px",left:"-3px"}}>
                        CVD
                    </div> : null }
                    <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative" style={{height:"220px"}}>
                        <button type="button" className="close btn btn-light btn-text-primary btn-hover-text-primary font-weight-bolder font-size-h3" onClick={() => RemoveRecently(props)}>
                            <span aria-hidden="true"><IoCloseSharp /></span>
                            <span className="sr-only">Close</span>
                        </button>
                        <LazyImageComponent data={props}/>
                    </div>
                    <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                       <div>
                       <div className="d-inline-block cursor-pointer" onClick={() => handelDetails(props)}>{props.diamondType + "-" + props.stockId}</div>

                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {
                                        props.availability === 'Memo' || props.availability === 'Hold'
                                            ? `Business Process`
                                            : props.availability === "Guaranteed"
                                            ? `Guaranteed`
                                            : props.availability === "Not Available"
                                            ? `Not Available`
                                            : props.availability === "Subject to Prior sale"
                                            ? `Subject to Prior sale`
                                            : `Unknown`
                                        }
                                    </Tooltip>
                                }
                            >
                                <img
                                    className="availIco mx-2"
                                    alt={props.availability}
                                    src={toAbsoluteUrl(`/media/svg/${
                                        props.availability === 'Memo' || props.availability === 'Hold'
                                            ? `availability_primary.svg`
                                            : props.availability === "Guaranteed"
                                            ? `availability_success.svg`
                                            : props.availability === "Not Available"
                                            ? `availability_danger.svg`
                                            : props.availability === "Subject to Prior sale"
                                            ? `availability_warning.svg`
                                            : `availability_blank_white.svg`
                                    }`)}
                                />
                                
                            </OverlayTrigger>
                       </div>
                        <div >
                        <>
                                    
                                    {props.lab}:<CertificateLink Certificate_link={props.Certi_link} lab={props.lab} certificate={props.certificate} hideLabName  className={`mx-2 text-dark text-left`} />
                                    <CopyUrlAlert text={props.certificate}/>
                                </>
                             
                        </div>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <div className="d-flex advTag justify-content-end align-items-center">
                            {/* shade */}
                            {renderShadeLabel(props.shade)}
                            
                            { props.milky && props.milky.toLowerCase() === "no milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                NO MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label font-weight-bold label-light-no_milky label-pill label-inline label-sm  mr-1 px-2">NO.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { props.milky && props.milky.toLowerCase() === "luster low" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Luster Low
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label font-weight-bold label-light-luster_low label-pill label-inline label-sm  mr-1 px-2">LL</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { props.milky && props.milky.toLowerCase() === "light milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                LIGHT MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label font-weight-bold label-light-light_milky label-pill label-inline label-sm  mr-1 px-2">L.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { props.milky && props.milky.toLowerCase() === "milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label font-weight-bold label-light-milky label-pill label-inline label-sm  mr-1 px-2">H.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* eyeclean */}
                            { props.eyeClean && props.eyeClean.toLowerCase() === "yes" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                100% Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label font-weight-bold label-light-info label-pill label-inline label-sm  mr-1 px-2">100% EC</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { props.eyeClean && props.eyeClean.toLowerCase() === "no" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                No Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label font-weight-bold label-light-danger label-pill label-inline label-sm  mr-1 px-2">EC: NO</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { (props.diamondType === "L" ) && (props.treatment && props.treatment.toLowerCase() === "as grown") ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                As Grown
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label font-weight-bold label-light-success label-pill label-inline label-sm  px-2">As-G</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                            {/* natural treated */}
                            { (props.diamondType === "N" || props.diamondType === "W") && (props.treatment && props.treatment.toLowerCase() !== "none")  ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Treated
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label font-weight-bold label-light-danger label-pill label-inline label-sm  px-2">Tx</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                            <ImagePopupCommon data={props}/>
                            <VideoPopupCommon data={props} userinfo={props.userinfo}/>
                            {props.certificate  && (
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">Certificate</Tooltip>
                                        }
                                    >
                                        
                                        <CertificateLink Certificate_link={props.Certi_link} lab={props.lab} certificate={props.certificate} useIcon className={`mx-2 text-primary text-left`} />
                                    </OverlayTrigger>
                                </span>
                            )}
                        </div>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex px-2 align-items-center">
                        <div className="text-center">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {props.shape}
                                    </Tooltip>
                                }
                            >
                                <SVG width="40px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/${props.shape?.toLowerCase()}.svg`)} />
                            </OverlayTrigger>
                            {/* <div className="text-uppercase small">{props.shape}</div > */}
                        </div>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {props.carat?.toFixed(2)} {props.shape}{" "}
                                    {props.color}{" "}
                                    {props.clairity} {props.cut}{" "}
                                    {props.polish} {props.symmetry} {props.fluorescence}
                                </Tooltip>
                            }
                        >
                            <span className="text-dark font-weight-bold text-capitalize font-size-h5 flex-wrap d-flex ml-1">
                                <span className="pr-1">
                                    {props.carat?.toFixed(2)}
                                </span>
                                <span className="pr-1">{props.shape}</span>
                                <span className="pr-1">{props.color}</span>
                                <span className="pr-1">{props.clairity}</span>
                                <span className="pr-1">{props.cut}</span>
                                <span className="pr-1">{props.polish}</span>
                                <span className="pr-1">{props.symmetry}</span>
                                <span className="pr-1">{props.fluorescence}</span>
                            </span> 
                            
                        </OverlayTrigger>
                    </div>
                    <hr className="w-100 my-1" />
                    <div className="col-12 d-flex justify-content-between align-items-end">
                        <div className="d-flex flex-column">
                            <div className="small" >{props.ourDiscount ? <span className={`${(props.ourDiscount >= 0) ? 'text-success' : 'text-danger'}`}>{props.ourDiscount}%</span> : <span className={`text-success`}>0.00%</span>}</div>
                            {
                                props.symbol !=="$" ? <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                ${numberWithCommas(props.ourRate)}
                                            </Tooltip>
                                        }
                                    >
                                        <span className="text-muted small">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(props.ourRate * props.conversionrate)}</span ></span>
                                    </OverlayTrigger>
                                </> :
                                <span className="text-muted small">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(props.ourRate * props.conversionrate)}</span ></span>
                            }
                        </div>
                        <div className="grid-total-title">
                            <span className="text-dark small">{props.symbolname}</span>
                            {
                                props.symbol !=="$" ? <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                ${numberWithCommas(props.ourPrice)}
                                            </Tooltip>
                                        }
                                    >
                                        <span className="font-size-h3">{props.symbol}{numberWithCommas(props.ourPrice* props.conversionrate)}</span >
                                    </OverlayTrigger>
                                </> :
                               <span className="font-size-h3">{props.symbol}{numberWithCommas(props.ourPrice* props.conversionrate)}</span >
                            } 
                        </div>
                    </div>
                    <hr className="w-100 my-2" />
                   
                    <div className="col-12 d-flex justify-content-between px-2 mtdr">
                       <span className="text-muted font-size-sm">M: <span className="text-dark font-size-sm">{props.length}*{props.width}*{props.height}</span ></span>
                       <span className="text-muted font-size-sm">T: <span className="text-dark font-size-sm">{props.tablePercent}%</span ></span>
                       <span className="text-muted font-size-sm">D: <span className="text-dark font-size-sm">{props.depthPercent}%</span ></span>
                       {props.ratio ? <span className="text-muted font-size-sm">R: <span className="text-dark font-size-sm">{props.ratio || "-"}</span ></span> : ""}
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 px-2">
                        <span className="d-flex text-muted justify-content-between align-items-center font-size-sm">
                            <div>
                                <span className="text-dark-25 mr-2"><FaCalendarAlt /></span> 
                                {props.language.SEARCHGRID_SHIPPINGDAYS}: <span className="font-size-sm ml-1 text-dark ">{<>{props.shippingDays}{" "}{props.language.MUI_DAYS} </>}</span>
                            </div>
                            {props.country ? (
                                <OverlayTrigger
                                    placement="top"
                                    className="text-capitalize"
                                    overlay={
                                        <Tooltip id="tooltip">{props.country}</Tooltip>
                                    }
                                >
                                    <img
                                        className="flagIco mr-1"
                                        alt={props.value}
                                        src={toAbsoluteUrl(
                                            `/media/map/${props.country?.toLowerCase()}.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src =toAbsoluteUrl(
                                                `/media/map/other.png`
                                                )
                                            }}
                                    />
                                </OverlayTrigger>
                            ) : (
                                null
                            )}
                        </span>
                        {props.supplierName? (
                            ""
                        ):(
                            <>
                            <OverlayTrigger
                                    placement="top"
                                    className="text-capitalize"
                                    overlay={<Tooltip id="tooltip">{props.supplierName}</Tooltip>}
                                >
                                    <span className="d-flex text-muted align-items-center font-size-sm mt-2" > <span className="text-dark-25 mr-2"><FaUserCircle /></span> {props.language.MUI_SUPPLIER}: <span className="font-size-sm ml-1 text-dark text-truncate">{props.supplierName}</span></span>
                                </OverlayTrigger></> 
                        )}
                    </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message="You can not shortlist stone without session"
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message="Your stone is shortlisted"
                />
            </Snackbar>
            
            <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
        </>
    );
};
