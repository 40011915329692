import React, { useState,useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { NODE_API_URL } from "../../../../../env_config";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const UserEditPopup = (props) => {
    const hideModel = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onHide();
    };
    const responseClose = (props) => {
        props.responseOnOk();
    };
    // const initialValues = {
    //     ListName: props.shortlistUserData.ListName,
    //     ConsumerName: props.shortlistUserData.ConsumerName,
    //     ContactNo: props.shortlistUserData.ContactNo,
    //     EMail: props.shortlistUserData.EMail,
    // };
    // console.log(props.shortlistUserData,"props.shortlistUserData")
    // console.log(initialValues,"initialValues")
    const [initialValues,setInitialValues] = useState({})
    useEffect(() => {
        const finalData = {
            ConsumerId: props.shortlistUserData.ConsumerId,
            CustomerId: props.userinfo.id,
        }
        // console.log(finalData,"finalData")
        axios({
            method: "POST",
            url: `${NODE_API_URL}/AUS/fetchWhitleLabelCustomer`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            if(res && res.data && res.data.data){
                // console.log(res.data.data,"RESSSS")
                setInitialValues({
                        ListName: res.data.data.ListName,
                        ConsumerName: res.data.data.ConsumerName,
                        ContactNo: res.data.data.ContactNo,
                        EMail: res.data.data.EMail,
                    })
            }
        }).catch(err => {
            
        })
    },[props.show])
    const phoneRegExp = /^[\+\d]?(?:[\d-.\s()]*)$/;
    const Schema = Yup.object().shape({
        EMail: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols").nullable(true),
        ContactNo: Yup.string()
            .matches(phoneRegExp, "Contact number is not valid").nullable(true),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // console.log(values);
        },
        onReset: (values, { resetForm }) => {
            // resetForm();
        },
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(formik.values,"SAVEHANDLE")
        const finalData = {
            ListName: formik.values.ListName || "",
            ConsumerName: formik.values.ConsumerName || "",
            ContactNo: formik.values.ContactNo || "",
            EMail: formik.values.EMail || "",
            ConsumerId: props.shortlistUserData.ConsumerId || "",
            CustomerId: props.userinfo.id || "",
        }
        // console.log(finalData,"finalData")
        axios({
            method: "POST",
            url: `${NODE_API_URL}/AUS/updateWhiteLabelCustomer`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            // console.log(res,"RESSSS")
            closeModel()
        }).catch(err => {
            
        })
    }
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size=""
                // backdrop="static"
                keyboard={false}
                // centered
            >
                <Modal.Header>
                    <Modal.Title>Edit User</Modal.Title>
                    <button
                        type="button"
                        className="close"
                        onClick={() => closeModel()}
                    >
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <form onSubmit={handleSubmit}>
                        <div className="px-8 py-5">
                            <div className="form-group row mb-0">
                                <label className="col-12 col-form-label cursor-na font-weight-bold">
                                    List Name
                                </label>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        placeholder="List Name"
                                        readOnly
                                        className={`form-control form-control-solid mb-2 ${getInputClasses(
                                            "ListName"
                                        )}`}
                                        name="ListName"
                                        {...formik.getFieldProps("ListName")}
                                    />
                                    {formik.touched.ListName &&
                                    formik.errors.ListName ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.ListName}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-12 col-form-label font-weight-bold">
                                    Consumer Name
                                </label>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        placeholder="Consumer Name"
                                        // readOnly
                                        className={`form-control mb-2 ${getInputClasses(
                                            "ConsumerName"
                                        )}`}
                                        name="ConsumerName"
                                        {...formik.getFieldProps(
                                            "ConsumerName"
                                        )}
                                    />
                                    {formik.touched.ConsumerName &&
                                    formik.errors.ConsumerName ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.ConsumerName}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-12 col-form-label font-weight-bold">
                                    Contact No
                                </label>
                                <div className="col-12 shortl">
                                    <PhoneInput
                                        placeholder="Contact Number"
                                        country={"in"}
                                        className={`form-control p-0 ${getInputClasses(
                                            "ContactNo"
                                        )}`}
                                        name="ContactNo"
                                        {...formik.getFieldProps("ContactNo")}
                                        onChange={(e) => formik.setFieldValue("ContactNo",e)}
                                        value={formik.values.ContactNo}
                                    />

                                    {formik.touched.ContactNo &&
                                    formik.errors.ContactNo ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.ContactNo}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-12 col-form-label font-weight-bold">
                                    E-Mail
                                </label>
                                <div className="col-12">
                                    <input
                                        type="email"
                                        placeholder="EMail"
                                        // readOnly
                                        className={`form-control mb-2 ${getInputClasses(
                                            "EMail"
                                        )}`}
                                        name="EMail"
                                        {...formik.getFieldProps("EMail")}
                                    />
                                    {formik.touched.EMail &&
                                    formik.errors.EMail ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.EMail}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 mt-5">
                            <div className="col-12 text-right p-5 border-top">
                                <button
                                    type="submit"
                                    className="btn btn-success mr-2"
                                    // disabled={
                                    //     formik.isSubmitting ||
                                    //     (formik.touched && !formik.isValid)
                                    // }
                                >
                                    Save Changes
                                    {/* {formik.isSubmitting} */}
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
