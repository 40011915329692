import React ,{useState} from "react";
import { Grid, InputLabel,TextField,makeStyles,Snackbar,SnackbarContent } from "@material-ui/core";
import clsx from "clsx";
import green from "material-ui/colors/green";
import { OverlayTrigger,Tooltip,Button,Alert} from "react-bootstrap";
import { FaCopy } from 'react-icons/fa';
import { TbRefresh } from 'react-icons/tb';
import { useHistory } from "react-router-dom";
import axios from "axios"
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie";
import { BsArrowLeftShort } from 'react-icons/bs';

const useStyles = makeStyles(theme => ({
    
    textField: {
      width: 400,
    //   backgroundColor: '#F3F6F9',
    },
    dense: {
        color: '#000000b8'
    },
    success: {
        backgroundColor: green[600],
    },
  }));


export default function IFrameEmbeddedCode(props) {
    // console.log(props.iframerules,"props.iframerules")
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertRegenOpen, setAlertRegenOpen] = useState(false);
    const checkrulesdiamondtype = (diamondtype) => {
        for(let i = 0; i < props.iframerules.length;i++){
            if(props.iframerules[i].diamond_type === diamondtype && props.iframerules[i].status){
                return true
            }
        }
        return false
    }
    // console.log(checkrulesdiamondtype("L"),"CHECXKKKK")
    function handleClose(event, reason) {
        setAlertOpen(false);
        setAlertRegenOpen(false);
    }

    const classes = useStyles();

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }

    const copylabcode = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_ENV === "production"?`<iframe src='https://nile.diazoom.com?auth=${props.iframesetting && props.iframesetting.ifm_lab_diamond_key}' title='Embeded Code' height='1000px' width='100%' style='border: none;'></iframe>`:`<iframe src='https://staging.aflatoon.diazoom.com?auth=${props.iframesetting && props.iframesetting.ifm_lab_diamond_key}' title='Embeded Code' height='1000px' width='100%' style='border: none;'></iframe>`)
        setAlertOpen(true);
    }

    const copynaturalcode = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_ENV === "production"?`<iframe src='https://nile.diazoom.com?auth=${props.iframesetting && props.iframesetting.ifm_nat_diamond_key}' title='Embeded Code' height='1000px' width='100%' style='border: none;'></iframe>`:`<iframe src='https://staging.aflatoon.diazoom.com?auth=${props.iframesetting && props.iframesetting.ifm_nat_diamond_key}' title='Embeded Code' height='1000px' width='100%' style='border: none;'></iframe>`)
        setAlertOpen(true);
    }

    const history = useHistory();
    const [regenerate,setRegenerate] = useState()
    const Regenerate = (key) => {
        axios({
            method: "POST",
            url: NODE_API_URL + "/AUS/generate-iframe-tokenNew",
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: {
              "key":key
            },
          }).then((res) => {
            if (res && res.data) {
                props.setUpdateKey(res.data)
                setRegenerate(true)
                setAlertRegenOpen(true)
            }
          }).
          catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    }
    return (
        <div>
            <div className="row">
                <div className="col-12 mb-4 setting-title d-flex justify-content-between pb-5 border-bottom" >
                        IFRAME EMBEDDED CODE
                    <span className="cursor-pointer text-hover-primary"  onClick={() =>history.push({ pathname: "/diamondfeed" })}><BsArrowLeftShort /> Back to dashboard</span>
                </div>
                <div className="col-12">
                    <Grid container justify="start" alignItems="center" className="mb-10" spacing={3}>
                    
                            <Grid item xs={12} md={8}>
                            {props.iframesetting && props.iframesetting.ifm_nat_diamond_key && !props.iframesetting.allow_natural_rule?<Alert variant="warning">
                                                Natural Diamonds Iframe Disabled , contact your customer representative 
                                             </Alert>:
                            !checkrulesdiamondtype("N")?<Alert variant="warning">
                                                Please Create/Activate Atleast One Natural Rule
                                             </Alert>:""}
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.DASHBOARD_SEARCH_NATURAL_DIAMOND}
                                        </InputLabel>
                                    </Grid>
                                    <Grid className="d-flex align-items-end" item xs={8}>
                                        <div className="d-flex align-items-end position-relative embed-box">
                                        {props.iframesetting && props.iframesetting.ifm_nat_diamond_key && props.iframesetting.allow_natural_rule && checkrulesdiamondtype("N")?
                                            <>
                                                <TextField
                                                    value={process.env.REACT_APP_ENV === "production"?`<iframe src='https://nile.diazoom.com?auth=${props.iframesetting && props.iframesetting.ifm_nat_diamond_key}' title='Embeded Code' height='1000px' width='100%' style='border: none;'></iframe>`:`<iframe src='https://staging.aflatoon.diazoom.com?auth=${props.iframesetting && props.iframesetting.ifm_nat_diamond_key}' title='Embeded Code' height='1000px' width='100%' style='border: none;'></iframe>`}
                                                    className={clsx(classes.textField, classes.dense)}
                                                    margin="dense"
                                                    variant="outlined"
                                                    multiline
                                                    label="Natural Diamond"
                                                    rows="6"
                                                    disabled={true}
                                                />
                                                <span className="embIcons">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Copy embeded code
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FaCopy onClick={() => copynaturalcode()} />
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Re-generate embeded code
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <TbRefresh className="ml-2"
                                                            onClick={() => Regenerate("N")}
                                                        />
                                                    </OverlayTrigger>
                                                </span>
                                            </> : <>
                                                    <TextField
                                                        value="Please Contact your Sales Representative for Natural Iframe Access."
                                                        className={clsx(classes.textField, classes.dense)}
                                                        margin="dense"
                                                        variant="outlined"
                                                        multiline
                                                        label="Natural Diamond"
                                                        rows="4"
                                                        disabled={true}
                                                    />
                                                    
                                                </>
                                             }
                                        </div>
                                        {/* <button className="btn btn-sm btn-primary mb-1 ml-2">{props.language.SETTING_DIAMOND_PREVIEW}</button> */}
                                        <Button 
                                            className="btn btn-sm btn-primary mb-1 ml-2" 
                                            onClick={() => history.push({
                                                pathname: "/iframe2.0-preview",
                                                state: props.iframesetting && props.iframesetting.ifm_nat_diamond_key,
                                                // state: decompress(res.data.ProductDetails),
                                            })} disabled={(props.iframesetting && props.iframesetting.ifm_nat_diamond_key && !props.iframesetting.allow_natural_rule) || !checkrulesdiamondtype("N")}>
                                                {props.language.SETTING_DIAMOND_PREVIEW}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                            </Grid>
                        
                            <Grid item xs={12} md={8}>
                            {props.iframesetting && props.iframesetting.ifm_nat_diamond_key && !props.iframesetting.allow_labgrown_rule?<Alert variant="warning">
                                                Lab Diamonds Iframe Disabled , contact your customer representative 
                                             </Alert>:!checkrulesdiamondtype("L")?<Alert variant="warning">
                                                Please Create/Activate Atleast One LabGrown Rule
                                             </Alert>:""}
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.DASHBOARD_SEARCH_LAB_DIAMOND}
                                        </InputLabel>
                                    </Grid>
                                    <Grid className="d-flex align-items-end" item xs={8}>
                                        <div className="d-flex align-items-end position-relative embed-box">
                                        {props.iframesetting && props.iframesetting.ifm_lab_diamond_key && props.iframesetting.allow_labgrown_rule && checkrulesdiamondtype("L")?
                                            <>
                                                <TextField
                                                    value={process.env.REACT_APP_ENV === "production"?`<iframe src='https://nile.diazoom.com?auth=${props.iframesetting && props.iframesetting.ifm_lab_diamond_key}' title='Embeded Code' height='1000px' width='100%' style='border: none;'></iframe>`:`<iframe src='https://staging.aflatoon.diazoom.com?auth=${props.iframesetting && props.iframesetting.ifm_lab_diamond_key}' title='Embeded Code' height='1000px' width='100%' style='border: none;'></iframe>`}
                                                    className={clsx(classes.textField, classes.dense)}
                                                    margin="dense"
                                                    variant="outlined"
                                                    multiline
                                                    label="Lab Diamond"
                                                    rows="6"
                                                    disabled={true}
                                                />
                                                <span className="embIcons">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Copy embeded code
                                                            </Tooltip>
                                                        }
    
                                                    >
                                                        <FaCopy 
                                                        onClick={() => copylabcode()}
                                                        />
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Re-generate embeded code
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <TbRefresh className="ml-2"
                                                        onClick={() => Regenerate("L")}
                                                        />
                                                    </OverlayTrigger>
                                                </span>
                                            </> : <>
                                                <TextField
                                                    value="Please Contact your Sales Representative for Labgrown Iframe Access."
                                                    className={clsx(classes.textField, classes.dense)}
                                                    margin="dense"
                                                    variant="outlined"
                                                    multiline
                                                    label="Lab Diamond"
                                                    rows="4"
                                                    disabled={true}
                                                />
                                            </>
                                        }
                                        </div>
                                        <Button className="btn btn-sm btn-primary mb-1 ml-2" onClick={() => history.push({
                                                pathname: "/iframe2.0-preview",
                                                state: props.iframesetting && props.iframesetting.ifm_lab_diamond_key,
                                                    // state: decompress(res.data.ProductDetails),
                                                })}
                                                disabled={(props.iframesetting && props.iframesetting.ifm_lab_diamond_key && !props.iframesetting.allow_labgrown_rule) || !checkrulesdiamondtype("L")}
                                                >{props.language.SETTING_DIAMOND_PREVIEW}</Button>
                                    </Grid>
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                            </Grid>
                    </Grid>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={alertOpen}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`Embedded code copied to clipboard`}
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={alertRegenOpen}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`Your new embeded code generated`}
                />
            </Snackbar>
        </div>
    );
}