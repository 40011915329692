import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {Switch,FormControlLabel,FormGroup  } from '@material-ui/core';
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import axios from 'axios';
import Cookies from "js-cookie";
import { FaAward ,FaTrashAlt,FaRegEdit ,FaCopy,FaUser, FaRegUser, FaRegCopy, FaRegTrashAlt} from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify';
import { Skeleton } from '@material-ui/lab';
function countryGroup (x) {
    var country = x.split(',').map(function (x, index) {
        return <span className="label label-outline-primary label-pill label-inline bg-primary-o-30 px-5 mr-1" key={index}>{ x }</span>; 
    });

    return <span>{country}</span>;
}

// function dateFormate(date){
//     let formattedDate = "";
//     if (date) {
//         const dateObj = new Date(date);
//         const year = dateObj.getFullYear();
//         const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
//         const day = ('0' + dateObj.getDate()).slice(-2);
//         const timeString = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
//         formattedDate = `${year}-${month}-${day} ${timeString}`;
//     }
//     return formattedDate;
// }    
export const MockShortListColumn = (history,props,setDeleteObj,setSwalOpen,countarray,setCountLoader,countloader,customeriframeorapi) => [

    {
        Header:"Rule Name",
        accessor:'rule_name',
        className:"align-middle text-center",
    },
    {
        Header:"Diamond Type",
        accessor: (d) => {
            return (
                <>
                <div className={`mb-1 ${(d.naturaldiamond || d.naturalfancydiamond) ? "natTab" : "labTab"} pl-20`}>
                    <span
                                className={`${d.naturaldiamond?"W":d.naturalfancydiamond?"F":d.labdiamond?"L":"LF"}_diamond mr-3 small`}
                            >
                                {d.naturaldiamond?"N":d.naturalfancydiamond?"NF":d.labdiamond?"L":"LF"}
                            </span>
                            <span>
                            {d.naturaldiamond?"Natural":d.naturalfancydiamond?"Natural Fancy":d.labdiamond?"Labgrown":"Labgrown Fancy"}

                            </span>
                            </div>
                </>
            );
        },
        className:"align-middle text-left text-norap",
    },
    {
        Header:"Diamond Criteria",
        accessor: (d) => {
            return (
                <div className='pl-10'>

                    {(d.naturaldiamond || d.naturalfancydiamond)?d.shape.toString().slice(0,10) + "... | " + d.min_carat + '-' + d.max_carat + " | " + (d.naturaldiamond?d.color.toString().slice(0,10):d.diamondfancy_color.toString().slice(0,10)) + "... | " + d.clarity.toString().slice(0,10) + "...":d.lab_shape.toString().slice(0,10) + "... | " + d.lab_min_carat + '-' + d.lab_max_carat + " | " + (d.labdiamond?d.lab_color.toString().slice(0,10):d.lab_fancy_color.toString().slice(0,10)) + "... | " + d.lab_clarity.toString().slice(0,10) + "..."}
                </div>
            );
        },
        className:"align-middle text-left text-norap",
    },
    {
        Header:"Markup Type",
        accessor: (d) => {
            return (
                <>
                    {d.markupname}
                </>
            );
        },
        className:"align-middle text-center",
    },
    {
        Header:"Rule Status",
        accessor: (d) => {
            const UpdateStatus = (rule) => {
                let rulestatus
                if(rule.status === 1){
                    rulestatus = 0
                }
                else{
                    rulestatus = 1
                }
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/AUS/update-ccmode-rule-status`,
                    headers: {
                        "Content-Type": "application/json",
                         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                    },
                    data: {
                        rule_id:rule.rule_id,
                        status:rulestatus
                    },
                }).then(res => {
                    if(res && res.data && res.data.data){
                        // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                        props.setUpdateRules(!props.updaterule)
                        if (rulestatus === 1) {
                            toast.success(`Rule Name ${rule.rule_name} turned on`)
                        }
                        else {
                            toast.error(`Rule Name ${rule.rule_name} turned off`)
                        }
                    }
                })
                .catch(error => {
                    if(error?.response?.data?.message === "UnAuthorized!"){
                      var cookies = document.cookie.split(";");
                    for (var i = 0; i < cookies.length; i++) {
                      if(cookies[i] && !cookies[i].includes("view")){
                        var equals = cookies[i].indexOf("=");
                        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                      }
                    }
                    
                    window.location.replace(`${BASE_URL}/logout`);
                  }
                  })
            }
            return (
                <>
                    <FormControlLabel
                        className='m-0'
                        control={
                        <Switch
                            checked={d.status === 1}
                            onClick={() => UpdateStatus(d)}
                            value={d.status}
                            color="secondary"
                        />
                        }
                        // label="Primary"
                    />
                </>
            );
        },
        className:"align-middle text-center",
    },   
    {
        Header:"Action",
        accessor: (d) => {
            const DeleteRule = (data) => {
                setSwalOpen(true)
                setDeleteObj({
                    user_id:data.user_id,
                    rule_id:data.rule_id
                })
            }
            const CopyRule = (d) => {
                // if(!props.supplieron){
                //     toast.error("Please Turn on Suppliers")
                //     return
                // }
                if(props.ccmoderules.length > 9){
                    toast.error("Maximum 10 Rules are Allowed")
                    return
                }
                history.push({
                    pathname: "/ccmode-copy-rule",
                    state: {
                        rule_id:d.rule_id
                    }
                        // state: decompress(res.data.ProductDetails),
                    })
            }
            const EditRule = (d) => {
                // if(!props.supplieron){
                //     toast.error("Please Turn on Suppliers")
                //     return
                // }
                history.push({
                    pathname: "/ccmode-update-rule",
                    state: {
                        rule_id:d.rule_id
                    }
                        // state: decompress(res.data.ProductDetails),
                    })
            }
            const ViewSupplier = (data) => {
                history.push({
                    pathname: "/rule-suppliers",
                    state: {
                        ...data,
                        api_id:customeriframeorapi.api_id
                    }
                        // state: decompress(res.data.ProductDetails),
                    })
            }
            return (
                <>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to edit rule
                            </Tooltip>
                        }
                    >
                        <button className="btn btn-text-dark btn-hover-light-dark btn-icon" onClick={() => EditRule(d)}><FaRegEdit className='font-size-h4' /></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to clone rule
                            </Tooltip>
                        }
                    >
                        <button className="btn btn-text-dark btn-hover-light-dark btn-icon" onClick={() => CopyRule(d)}><FaRegCopy className='font-size-h4' /></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to delete rule
                            </Tooltip>
                        }
                    >
                        <button className="btn btn-text-dark btn-hover-light-dark btn-icon" onClick={() => DeleteRule(d)}><FaRegTrashAlt className='font-size-h4' /></button>
                    </OverlayTrigger>
                </>
            );
        },
        className:"text-center align-middle",
    },
   
]