import React, {useMemo,useState,useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { MockShortListColumn } from './Columns'; 
import { GlobleFilter } from '../../tableFilter';
import Cookies from "js-cookie";
import {FaListUl, FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaTrashAlt } from "react-icons/fa";
// import { CheckBox } from './CheckBox';
import { SwalWarn } from '../../Popup';
import { Dropdown, Modal,OverlayTrigger,Tooltip } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
// import  {ExpandedDetails} from "./Expand/ExpandedDetails"
import axios from 'axios';

import { NODE_API_URL,BASE_URL } from '../../../../env_config';
export const ShortListTable = (props) => {
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [delData,setDelData] = useState([])
    const [alertMessage,setAlertMessage] = useState()
    const handleClose = () => setCustomizeTable(false);
    const history = useHistory();
    const columns = useMemo(()=> MockShortListColumn(props.language,props.userinfo),[] )
    const [data,setData] = useState([])
    const [update,setUpdate] = useState(false)
    // console.log(props)
    useEffect(() => {
        axios({
            method: "get",
            url: `${NODE_API_URL}/AUS/short-listing-record`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            }
        }).then(res => {
            if(res && res.data && res.data.data && res.data.data){
                setData(res.data.data)
            }
            else{
                setData([])
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    },[update])
    // console.log(update,"update")
    const DeleteSwal = (row) => {
        // setSemiAprroveModal(true)
        setDelData(row)
        setSwalWarnOpen(true)
        setAlertMessage("Are you sure want to Delete !!")
        
    } 
    const DeleteConsumer = () => {
        // console.log(data,"DeleteConsumer")
        const finalData = {
            ConsumerId:delData.ConsumerId
        }
        axios({
            method: "delete",
            url: `${NODE_API_URL}/AUS/delete-white-label-customer`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        }).then(res => {
            // console.log("CHECK")
            setSwalWarnOpen(false)
            setUpdate(res.data)
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{ globalFilter, pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { 
            sortBy: [
                {
                    id: 'CreatedAt',
                    desc: true
                }
            ]
         }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    // {
                    //     id:'selection',
                    //     Header:({ getToggleAllPageRowsSelectedProps }) =>(
                    //         <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                    //     ),
                    //     Cell: ({ row }) => (
                    //         <div>
                    //           <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                    //         </div>
                    //       ),
                    // },
                    ...columns,
                    {
                        Header: () => "Actions",
                        id: 'expander',
                        Cell: ({ row }) => (
                            <span  {...row.getToggleRowExpandedProps()}>
                                {/* {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  row.original.ShortListedStones?<FaChevronRight className="text-primary" title="Expand"/>:""} */}
                                { row.original.ShortListedStones ?
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Details
                                            </Tooltip>
                                        }
                                    >
                                        <button className='btn btn-primary mr-2 btn-sm' onClick={() => history.push({ pathname: "/ccmode-shortlist", state: row.original})}><FaListUl /></button> 
                                    </OverlayTrigger> : null
                                }
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Delete consumer
                                        </Tooltip>
                                    }
                                >
                                    <button className='btn btn-danger mr-2 btn-sm' onClick={() => DeleteSwal(row.original)}><FaTrashAlt /></button> 
                                </OverlayTrigger>
                                {/* <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Session history
                                        </Tooltip>
                                    }
                                >
                                    <button className='btn btn-info mr-2 btn-sm'><FaHistory  /></button> 
                                </OverlayTrigger> */}
                            </span>
                        ),
                    },
                    
                ]
            })
        }
    )
    return (
        <>
        <div>
            <div className="row">
                <div className="col-12 py-3 d-flex justify-content-between align-items-center">
                    <div>Added to cart by your customers.</div>
                    <div className='d-flex'>
                        <div><GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} /></div>
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2 confirmResult">
                                    <div>
                                       <div>
                                            <label className="checkbox my-2 border p-2">
                                                <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                <span className="mr-2"></span>
                                                Show All
                                            </label> 
                                       </div>
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id} className={`${column.id}`}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="col-12 pt-7 pb-0 mt-n3">
                    <div className="table-responsive w-100">
                        <table className="table table-hover" {...getTableProps()}>
                            <thead className="custom_datatable_head" >
                                {headerGroups.map(headerGroup =>(
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        { headerGroup.headers.map((column)=>(
                                            <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="text-secondary">
                                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {data.length > 0 && page.map((row,i) => {
                                    prepareRow(row)
                                    return(
                                        <React.Fragment key={i}>
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}  onClick={() => cell.column.id !== "expander"?history.push({ pathname: "/ccmode-shortlist", state: row.original}):""}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {/* {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null} */}
                                        </React.Fragment>
                                    )
                                }) || <tr><td className="text-center bg-gray-200" colSpan="100%">No Data Available in Table</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                        <div>
                            <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {pageOptions.length}{'  '}
                            </span>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                            <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                            <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={() => setSwalWarnOpen(false)} onClose={() => setSwalWarnOpen(false)} responseOnOk={() => DeleteConsumer()}/> 
        </>
    )
}