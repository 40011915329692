/* eslint-disable */
import kycFormModel from './kycFormModel';
import axios from 'axios';
import { NODE_API_URL,BASE_URL } from '../../../../../env_config';
import Cookies from "js-cookie";
const {
  formField: {
    naturaldiamond,
    naturalfancydiamond,
    lgwhitediam,
    lgfancydiam,
    diamondshape,
    carat_from,
    carat_to,
    diamondcolor,
    diamondfancy_color,
    diamondfancy_intensity,
    diamondfancy_overtone,
    diamondclarity,
    lab_val,
    cuts,
    diamondflour,
    polishes,
    symmetries,
    diamondshade,
    milky,
    eyeclean,
    price_from,
    price_to,
    total_price_from,
    total_price_to,
    media,


    lab_shape,
    lab_carat_from,
    lab_carat_to,
    lab_color,
    lab_fancy_color,
    lab_fancy_intensity,
    lab_fancy_overtone,
    lab_clarity,
    lab_lab,
    lab_cuts,
    lab_polishes,
    lab_symmetries,
    lab_flourselect,
    lab_shadeselect,
    lab_milky,
    lab_eyeclean,
    lab_min_dollarperct,
    lab_max_dollarperct,
    lab_total_price_from,
    lab_total_price_to,
    lab_all_media,

    primarycolor,
    secondary,
    fontcolor,
    diamondmarkup,
    diamondfancymarkup,
    lab_diamondmarkup,
    lab_diamondfancymarkup,
    website_url,
    inquiry_email,
    inquiry_cc_email,
    multi_currency,
    multi_language,
    dbfetch,
    suppliers,
    rulename,
    markupvalue,
    markupoption,
    brand,
    origin,
    treatment,
    keytosymbol,
    labbrand,
    laborigin,
    labtreatment,
    labkeytosymbol,
    caratpricemarkups,
    rule_id,
    labmeasurementlengthfrom,
    labmeasurementlengthto,
    labmeasurementwidthfrom,
    labmeasurementwidthto,
    labmeasurementheightfrom,
    labmeasurementheightto,
    labtablefrom,
    labtableto,
    labdepthfrom,
    labdepthto,
    labratiofrom,
    labratioto,
    labcrownheightfrom,
    labcrownheightto,
    labcrownanglefrom,
    labcrownangleto,
    labpavheightfrom,
    labpavheightto,
    labpavanglefrom,
    labpavangleto,
    measurementlengthfrom,
    measurementlengthto,
    measurementwidthfrom,
    measurementwidthto,
    measurementheightfrom,
    measurementheightto,
    tablefrom,
    tableto,
    depthfrom,
    depthto,
    ratiofrom,
    ratioto,
    crownheightfrom,
    crownheightto,
    crownanglefrom,
    crownangleto,
    pavheightfrom,
    pavheightto,
    pavanglefrom,
    pavangleto,
    perctinclusive,
    availability,
    labavailability
  }
} = kycFormModel;
export const fetchData = async(userinfo,location) => {
  const userData = JSON.parse(localStorage.getItem("userData"))
  const finalData = {
    "rule_id":location && location.state && location.state.rule_id?location.state.rule_id:""
}
const promise = await axios({
  method: "get",
  url: `${NODE_API_URL}/AUS/fetch-ccmode-rules-byid`,
  headers: {
    "Content-Type": "application/json",
     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
},
params: finalData,
}).then(res => {
    if (res && res.data && res.data.data && res.data.data !== "Record Not Found!") {
      // console.log(res.data.data.shape.split(','), "fetchRulesData")
      // setCCModeWhiteLabelData(res.data.data)
      const splitString = (str) => {
        // Check if "Memo,Hold" is present
        if (str.includes("Memo,Hold")) {
          // Temporarily replace "Memo,Hold" with a placeholder that doesn't conflict with other commas
          str = str.replace("Memo,Hold", "MemoHold");
      
          // Split the string by commas
          const splitArr = str.split(/,(?=\S)/);
      
          // After splitting, replace "MemoHold" back with "Memo,Hold"
          return splitArr.map(item => item === "MemoHold" ? "Memo,Hold" : item);
        }
      
        // If "Memo,Hold" is not present, just split by commas
        return str.split(/,(?=\S)/);
      };
      let ruleObj = {
        [availability.name]: res.data.data.availability?splitString(res.data.data.availability):[],
        [rulename.name]:res.data.data.rule_name + " Copy",
        [rule_id.name]:res.data.data.rule_id,
        [naturaldiamond.name]: res.data.data.naturaldiamond?1:'',
        [naturalfancydiamond.name]: res.data.data.naturalfancydiamond?1:'',
        [lgwhitediam.name]: res.data.data.labdiamond?1:'',
        [lgfancydiam.name]: res.data.data.labfancydiamond?1:'',
        [diamondshape.name]: res.data.data.shape?res.data.data.shape.split(','):[],
        [carat_from.name]: res.data.data.min_carat,
        [carat_to.name]: res.data.data.max_carat,
        [diamondcolor.name]: res.data.data.color?res.data.data.color.split(','):[],
        [diamondfancy_color.name]: res.data.data.diamondfancy_color?res.data.data.diamondfancy_color.split(','):[],
        [diamondfancy_intensity.name]: res.data.data.diamondfancy_intensity?res.data.data.diamondfancy_intensity.split(','):[],
        [diamondfancy_overtone.name]: res.data.data.diamondfancy_overtone?res.data.data.diamondfancy_overtone.split(','):[],
        [diamondclarity.name]: res.data.data.clarity?res.data.data.clarity.split(','):[],
        [lab_val.name]: res.data.data.lab?res.data.data.lab.split(','):[],
        [cuts.name]: res.data.data.cut?res.data.data.cut.split(','):[],
        [diamondflour.name]: res.data.data.fluorescence?res.data.data.fluorescence.split(','):[],
        [polishes.name]: res.data.data.polish?res.data.data.polish.split(','):[],
        [symmetries.name]: res.data.data.symmetry?res.data.data.symmetry.split(','):[],
        [diamondshade.name]: res.data.data.shade?res.data.data.shade.split(','):[],
        [milky.name]: res.data.data.milky?res.data.data.milky.split(','):[],
        [eyeclean.name]: res.data.data.eyeclean?res.data.data.eyeclean.split(','):[],
        [price_from.name]: res.data.data.min_dollarperct,
        [price_to.name]: res.data.data.max_dollarperct,
        [total_price_from.name]: res.data.data.total_price_from,
        [total_price_to.name]: res.data.data.total_price_to,
        [media.name]: res.data.data.media?res.data.data.media.split(','):[],

        [measurementlengthfrom.name]: res.data.data.minlength,
        [measurementlengthto.name]: res.data.data.maxlength,
        [measurementwidthfrom.name]: res.data.data.minwidth,
        [measurementwidthto.name]: res.data.data.maxwidth,
        [measurementheightfrom.name]: res.data.data.minheight,
        [measurementheightto.name]: res.data.data.maxheight,
        [tablefrom.name]: res.data.data.tablemin,
        [tableto.name]: res.data.data.tablemax,
        [depthfrom.name]: res.data.data.depthmin,
        [depthto.name]: res.data.data.depthmax,
        [ratiofrom.name]: res.data.data.ratiomin,
        [ratioto.name]: res.data.data.ratiomax,
        [crownheightfrom.name]: res.data.data.crheightmin,
        [crownheightto.name]: res.data.data.crheightmax,
        [crownanglefrom.name]: res.data.data.cranglemin,
        [crownangleto.name]: res.data.data.cranglemax,
        [pavheightfrom.name]: res.data.data.pavheightmin,
        [pavheightto.name]: res.data.data.pavheightmax,
        [pavanglefrom.name]: res.data.data.pavanglemin,
        [pavangleto.name]: res.data.data.pavanglemax,
        [brand.name]: res.data.data.brand?res.data.data.brand.split(','):[],
        [origin.name]: res.data.data.origin?res.data.data.origin.split(','):[],
        [treatment.name]: res.data.data.treatment?res.data.data.treatment.split(','):[],
        [keytosymbol.name]: res.data.data.keytosymbol?res.data.data.keytosymbol.split(','):[],

        [labavailability.name]: res.data.data.labavailability?splitString(res.data.data.labavailability):[],
        [lab_shape.name]: res.data.data.lab_shape?res.data.data.lab_shape.split(','):[],
        [lab_carat_from.name]: res.data.data.lab_min_carat,
        [lab_carat_to.name]: res.data.data.lab_max_carat,
        [lab_color.name]: res.data.data.lab_color?res.data.data.lab_color.split(','):[],
        [lab_fancy_color.name]: res.data.data.lab_fancy_color?res.data.data.lab_fancy_color.split(','):[],
        [lab_fancy_intensity.name]: res.data.data.lab_fancy_intensity?res.data.data.lab_fancy_intensity.split(','):[],
        [lab_fancy_overtone.name]: res.data.data.lab_fancy_overtone?res.data.data.lab_fancy_overtone.split(','):[],
        [lab_clarity.name]: res.data.data.lab_clarity?res.data.data.lab_clarity.split(','):[],
        [lab_lab.name]: res.data.data.lab_lab?res.data.data.lab_lab.split(','):[],
        [lab_cuts.name]: res.data.data.lab_cut?res.data.data.lab_cut.split(','):[],
        [lab_polishes.name]: res.data.data.lab_polish?res.data.data.lab_polish.split(','):[],
        [lab_symmetries.name]: res.data.data.lab_symmetry?res.data.data.lab_symmetry.split(','):[],
        [lab_flourselect.name]: res.data.data.lab_fluorescence?res.data.data.lab_fluorescence.split(','):[],
        [lab_shadeselect.name]: res.data.data.lab_shade?res.data.data.lab_shade.split(','):[],
        [lab_milky.name]: res.data.data.lab_milky?res.data.data.lab_milky.split(','):[],
        [lab_eyeclean.name]: res.data.data.lab_eyeclean?res.data.data.lab_eyeclean.split(','):[],
        [lab_min_dollarperct.name]: res.data.data.lab_min_dollarperct,
        [lab_max_dollarperct.name]: res.data.data.lab_max_dollarperct,
        [lab_total_price_from.name]: res.data.data.lab_total_price_from,
        [lab_total_price_to.name]: res.data.data.lab_total_price_to,
        [lab_all_media.name]: res.data.data.lab_media?res.data.data.lab_media.split(','):[],

        [labmeasurementlengthfrom.name]: res.data.data.labminlength,
        [labmeasurementlengthto.name]: res.data.data.labmaxlength,
        [labmeasurementwidthfrom.name]: res.data.data.labminwidth,
        [labmeasurementwidthto.name]: res.data.data.labmaxwidth,
        [labmeasurementheightfrom.name]: res.data.data.labminheight,
        [labmeasurementheightto.name]: res.data.data.labmaxheight,
        [labtablefrom.name]: res.data.data.labtablemin,
        [labtableto.name]: res.data.data.labtablemax,
        [labdepthfrom.name]: res.data.data.labdepthmin,
        [labdepthto.name]: res.data.data.labdepthmax,
        [labratiofrom.name]: res.data.data.labratiomin,
        [labratioto.name]: res.data.data.labratiomax,
        [labcrownheightfrom.name]: res.data.data.labcrheightmin,
        [labcrownheightto.name]: res.data.data.labcrheightmax,
        [labcrownanglefrom.name]: res.data.data.labcranglemin,
        [labcrownangleto.name]: res.data.data.labcranglemax,
        [labpavheightfrom.name]: res.data.data.labpavheightmin,
        [labpavheightto.name]: res.data.data.labpavheightmax,
        [labpavanglefrom.name]: res.data.data.labpavanglemin,
        [labpavangleto.name]: res.data.data.labpavanglemax,
        [labbrand.name]: res.data.data.labbrand?res.data.data.labbrand.split(','):[],
        [laborigin.name]: res.data.data.laborigin?res.data.data.laborigin.split(','):[],
        [labtreatment.name]: res.data.data.labtreatment?res.data.data.labtreatment.split(','):[],
        [labkeytosymbol.name]: res.data.data.labkeytosymbol?res.data.data.labkeytosymbol.split(','):[],
        // [muti_currency.name]: 'INR',
        // [diamondmarkup.name]: res.data.data.diamondmarkup,
        // [diamondfancymarkup.name]: res.data.data.diamondfancymarkup,
        // [lab_diamondmarkup.name]: res.data.data.lab_diamondmarkup,
        // [lab_diamondfancymarkup.name]: res.data.data.lab_diamondfancymarkup,
        // // [white_label_logo.name]: [],
        // [primarycolor.name]: res.data.data.primarycolor_code,
        // [secondary.name]: res.data.data.secondarycolor_code,
        // [fontcolor.name]: res.data.data.fontcolor_code,
        // [website_url.name]: res.data.data.website_url,
        // [inquiry_email.name]: res.data.data.inquiry_email,
        // [inquiry_cc_email.name]: res.data.data.inquiry_cc_email,
        [dbfetch.name]:true,
        [markupvalue.name]:'',
        [markupoption.name]:res.data.data.markupname,
        [suppliers.name]:[],
        [multi_currency.name]:res.data.data.currency,
        [brand.name]:res.data.data.brand?res.data.data.brand.split(','):[],
        [origin.name]:res.data.data.origin?res.data.data.origin.split(','):[],
        [treatment.name]:res.data.data.treatment?res.data.data.treatment.split(','):[],
        [keytosymbol.name]:res.data.data.keytosymbol?res.data.data.keytosymbol.split(','):[],
        [labbrand.name]:res.data.data.labbrand?res.data.data.labbrand.split(','):[],
        [laborigin.name]:res.data.data.laborigin?res.data.data.laborigin.split(','):[],
        [labtreatment.name]:res.data.data.labtreatment?res.data.data.labtreatment.split(','):[],
        [labkeytosymbol.name]:res.data.data.labkeytosymbol?res.data.data.labkeytosymbol.split(','):[],
        [caratpricemarkups.name]:res.data.data.caratpricemarkups?res.data.data.caratpricemarkups:[],
        [perctinclusive.name]:res.data.data.perctinclusive
      }
      return ruleObj
    }
    else {
      return {
        [availability.name]:[],
        [rulename.name]: '',
        [naturaldiamond.name]: '',
        [naturalfancydiamond.name]: '',
        [lgwhitediam.name]: '',
        [lgfancydiam.name]: '',
        [diamondshape.name]: [],
        [carat_from.name]: '0.18',
        [carat_to.name]: '10.00',
        [diamondcolor.name]: [],
        [diamondfancy_color.name]: [],
        [diamondfancy_intensity.name]: [],
        [diamondfancy_overtone.name]: [],
        [diamondclarity.name]: [],
        [lab_val.name]: [],
        [cuts.name]: [],
        [diamondflour.name]: [],
        [polishes.name]: [],
        [symmetries.name]: [],
        [diamondshade.name]: [],
        [milky.name]: [],
        [eyeclean.name]: [],
        [price_from.name]: '0',
        [price_to.name]: '999999',
        [total_price_from.name]: '0',
        [total_price_to.name]: '999999',
        [media.name]: [],

        [labavailability.name]:[],
        [lab_shape.name]: [],
        [lab_carat_from.name]: '0.18',
        [lab_carat_to.name]: '10.00',
        [lab_color.name]: [],
        [lab_fancy_color.name]: [],
        [lab_fancy_intensity.name]: [],
        [lab_fancy_overtone.name]: [],
        [lab_clarity.name]: [],
        [lab_lab.name]: [],
        [lab_cuts.name]: [],
        [lab_polishes.name]: [],
        [lab_symmetries.name]: [],
        [lab_flourselect.name]: [],
        [lab_shadeselect.name]: [],
        [lab_milky.name]: [],
        [lab_eyeclean.name]: [],
        [lab_min_dollarperct.name]: '0',
        [lab_max_dollarperct.name]: '999999',
        [lab_total_price_from.name]: '0',
        [lab_total_price_to.name]: '999999',
        [lab_all_media.name]: [],


        // [muti_currency.name]: 'INR',
        
        [dbfetch.name]:false,
        [markupvalue.name]:'',
        [markupoption.name]:'',
        [suppliers.name]:[],
        [multi_currency.name]:location.state.currencyvalue,
        [brand.name]:[],
        [origin.name]:[],
        [treatment.name]:[],
        [keytosymbol.name]:[],
        [labbrand.name]:[],
        [laborigin.name]:[],
        [labtreatment.name]:[],
        [labkeytosymbol.name]:[],
        [caratpricemarkups.name]:[],
        [perctinclusive.name]:false
      }
    }
  }).
  catch(error => {
    if(error?.response?.data?.message === "UnAuthorized!"){
      var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      if(cookies[i] && !cookies[i].includes("view")){
        var equals = cookies[i].indexOf("=");
        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
    
    window.location.replace(`${BASE_URL}/logout`);
  }
  return {
    [availability.name]:[],
    [rulename.name]: '',
    [naturaldiamond.name]: '',
    [naturalfancydiamond.name]: '',
    [lgwhitediam.name]: '',
    [lgfancydiam.name]: '',
    [diamondshape.name]: [],
    [carat_from.name]: '0.18',
    [carat_to.name]: '100.00',
    [diamondcolor.name]: [],
    [diamondfancy_color.name]: [],
    [diamondfancy_intensity.name]: [],
    [diamondfancy_overtone.name]: [],
    [diamondclarity.name]: [],
    [lab_val.name]: [],
    [cuts.name]: [],
    [diamondflour.name]: [],
    [polishes.name]: [],
    [symmetries.name]: [],
    [diamondshade.name]: [],
    [milky.name]: [],
    [eyeclean.name]: [],
    [price_from.name]: '',
    [price_to.name]: '',
    [total_price_from.name]: 0,
    [total_price_to.name]: 999999,
    [media.name]: [],

    [labavailability.name]:[],
    [lab_shape.name]: [],
    [lab_carat_from.name]: '0.18',
    [lab_carat_to.name]: '100.00',
    [lab_color.name]: [],
    [lab_fancy_color.name]: [],
    [lab_fancy_intensity.name]: [],
    [lab_fancy_overtone.name]: [],
    [lab_clarity.name]: [],
    [lab_lab.name]: [],
    [lab_cuts.name]: [],
    [lab_polishes.name]: [],
    [lab_symmetries.name]: [],
    [lab_flourselect.name]: [],
    [lab_shadeselect.name]: [],
    [lab_milky.name]: [],
    [lab_eyeclean.name]: [],
    [lab_min_dollarperct.name]: '',
    [lab_max_dollarperct.name]: '',
    [lab_total_price_from.name]: 0,
    [lab_total_price_to.name]: 999999,
    [lab_all_media.name]: [],


    // [muti_currency.name]: 'INR',
    
    [dbfetch.name]:false,
    [markupvalue.name]:'',
    [markupoption.name]:'',
    [suppliers.name]:[],
    [multi_currency.name]:"",
    [brand.name]:[],
    [origin.name]:[],
    [treatment.name]:[],
    [keytosymbol.name]:[],
    [labbrand.name]:[],
    [laborigin.name]:[],
    [labtreatment.name]:[],
    [labkeytosymbol.name]:[],
    [caratpricemarkups.name]:[],
    [perctinclusive.name]:false
  }
  })

  // const promise = ({
  //   [naturaldiamond.name]: '',
  //   [naturalfancydiamond.name]: '',
  //   [lgwhitediam.name]: '',
  //   [lgfancydiam.name]: '',
  //   [diamondshape.name]: [],
  //   [carat_from.name]: '0.18',
  //   [carat_to.name]: '10.00',
  //   [diamondcolor.name]: [],
  //   [diamondfancy_color.name]: [],
  //   [diamondfancy_intensity.name]: [],
  //   [diamondfancy_overtone.name]: [],
  //   [diamondclarity.name]: [],
  //   [lab_val.name]: [],
  //   [cuts.name]: [],
  //   [diamondflour.name]:[],
  //   [polishes.name]: [],
  //   [symmetries.name]: [],
  //   [diamondshade.name]: [],
  //   [milky.name]: [],
  //   [eyeclean.name]: [],
  //   [price_from.name]: '0',
  //   [price_to.name]: '999999',
  //   [total_price_from.name]: '0',
  //   [total_price_to.name]: '999999',
  //   [media.name]: '0',


  //   [lab_shape.name]: [],
  //   [lab_carat_from.name]: '0.18',
  //   [lab_carat_to.name]: '10.00',
  //   [lab_color.name]: [],
  //   [lab_fancy_color.name]: [],
  //   [lab_fancy_intensity.name]: [],
  //   [lab_fancy_overtone.name]: [],
  //   [lab_clarity.name]: [],
  //   [lab_clarity.name]: [],
  //   [lab_lab.name]: [],
  //   [lab_cuts.name]: [],
  //   [lab_polishes.name]: [],
  //   [lab_symmetries.name]: [],
  //   [lab_flourselect.name]: [],
  //   [lab_shadeselect.name]: [],
  //   [lab_milky.name]: [],
  //   [lab_eyeclean.name]: [],
  //   [lab_min_dollarperct.name]: '0',
  //   [lab_max_dollarperct.name]: '999999',
  //   [lab_total_price_from.name]: '0',
  //   [lab_total_price_to.name]: '999999',
  //   [lab_all_media.name]: '0',


  //   // [muti_currency.name]: 'INR',
  //   [diamondmarkup.name]: '12',
  //   [diamondfancymarkup.name]: '12',
  //   [lab_diamondmarkup.name]: '12',
  //   [lab_diamondfancymarkup.name]: '12',
  //   // [white_label_logo.name]: [],
  //   [primarycolor.name]: '#002173',
  //   [secondary.name]: '#ff0000',
  //   [fontcolor.name]: '#ffffff',
  //   [website_url.name]: '',
  //   [inquiry_email.name]: mail,
  //   [inquiry_cc_email.name]: '',
  // })
  // console.log(promise,"PROMISEE")
  return promise
}

