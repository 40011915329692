import React, { useEffect, useState } from "react";
// import { Field } from "formik";
// import {MultiSelect} from '../MultiSelect'
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../../../env_config";
import makeAnimated from 'react-select/animated';
import Cookies from "js-cookie";
import Select from 'react-select';


const animatedComponents = makeAnimated();

export const Location = ({language,formik,diamond_type}) => {

    const [locationOptions, setLocationOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if(diamond_type)
            {
                axios({
                    method: "post",
                    url: `${NODE_API_URL}/AUS/get-all-location`,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
                    },
                    data: {
                        "diamondType": diamond_type==="L"?"L":"N"
                    }
                })
                .then(function (res) {
                    if (res && res.data.success && res.data.data) {
                        const options = res.data.data.map(item => ({
                            value: item.country,
                            label: item.country.toUpperCase()
                        }));

                        const sortedOptions = options.sort((a, b) => {
                            if (a.label === "OTHER") return 1;
                            if (b.label === "OTHER") return -1;
                            return 0;
                        });

                        setLocationOptions(sortedOptions);
                    }
                })
                .catch(function (res) {
                    if (res?.response?.data?.message === "UnAuthorized!") {
                        var cookies = document.cookie.split(";");
                        for (var i = 0; i < cookies.length; i++) {
                            if (cookies[i] && !cookies[i].includes("view")) {
                                var equals = cookies[i].indexOf("=");
                                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                            }
                        }
                        window.location.replace(`${BASE_URL}/logout`);
                    }
                });
            }
    }, [diamond_type]);

    const filterOptions = (options, inputValue) => {
        return options.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    return (
        <>
            <div className="row mt-5">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">{language.LOCATION_LOCATION}</p>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-12 d-flex flex-wrap mb-5">
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={filterOptions(locationOptions, inputValue)}
                                onInputChange={setInputValue}
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions.map(option => option.value);
                                    formik.setFieldValue("Location", selectedValues);
                                }}
                                placeholder="Select Location"
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        width: '250px',
                                        color: 'black',
                                        // height: '40px',
                                        borderRadius:"25px",
                                        boxShadow: state.isFocused ? '0 1px 2px rgba(0, 0, 0, .14), 0 0 2px rgba(0, 0, 0, .12)' : null,
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                        maxHeight: '150px',
                                        overflowY: 'auto',
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: '150px',
                                        overflowY: 'auto',
                                    }),
                                    option: (provided, { isFocused }) => ({
                                        ...provided,
                                        textTransform: 'uppercase',
                                        backgroundColor: isFocused ? '#e0e0e0' : null,
                                    }),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};