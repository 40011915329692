import React, { useEffect, useState } from "react";
import {Shape} from "./Basic/Shape"
import {SieveSize} from "./Basic/SieveSize"
import {Color} from "./Basic/Color"
import {Clarity} from "./Basic/Clarity"
import {CaratPcs} from "./Basic/CaratPcs"
import { BASE_URL, NODE_API_URL } from "../../../../env_config";
import Cookies from "js-cookie";
import axios from "axios";

export const Basic = (props) => {
    const [shapeData,setShapeData] =useState([])
    const [colorData,setColorData] =useState([])
    const [clarityData,setClarityData] =useState([])

    useEffect(() => {
        if(props.dimondType){
            axios({
                method: "get",
                url: `${NODE_API_URL}/AUS/${props.dimondType === "PN" ? "N":"L"}/shape-color-clarity`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
              })
              .then(function(res) {
                if(res && res.data){
                    console.log(res.data)
                    setShapeData(res.data.shape)
                    setColorData(res.data.color)
                    setClarityData(res.data.clarity)
                }
                
              })
              .catch(function(res) {
               
                if(res?.response?.data?.message === "UnAuthorized!"){
                    var cookies = document.cookie.split(";");
                  for (var i = 0; i < cookies.length; i++) {
                    if(cookies[i] && !cookies[i].includes("view")){
                      var equals = cookies[i].indexOf("=");
                      var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    }
                  }
                  
                  window.location.replace(`${BASE_URL}/logout`);
                }
                // disableLoading();
              });
        }
    },[props.dimondType])
    // console.log(shapeData)

       return (
        <>
            <Shape language={props.language} shapeData={shapeData} formik={props.formik}/>
            <SieveSize rangeErr={props.rangeErr} language={props.language} formik={props.formik} setRangeErr={props.setRangeErr}/>
            <div className="row pb-8 border-bottom">
                <div className="col-md-6"><Color dimondType={props.dimondType} colorData={colorData} colorErr={props.colorErr} language={props.language} formik={props.formik} setColorErr={props.setColorErr}/></div>
                <div className="col-md-6"><Clarity dimondType={props.dimondType} clarityData={clarityData} clarityErr={props.clarityErr} language={props.language} formik={props.formik} setClarityErr={props.setClarityErr} /></div>
            </div>
            <CaratPcs language={props.language} formik={props.formik} caratErr={props.caratErr}  setCaratErr={props.setCaratErr} pcsErr={props.pcsErr} setPcsErr={props.setPcsErr} minCaratErr={props.minCaratErr} setMinCaratErr={props.setMinCaratErr}/>
        </>
    );
};
