/* eslint-disable */
import kycFormModel from './kycFormModel';
import axios from 'axios';
import { NODE_API_URL } from '../../../../../env_config';
const {
  formField: {

    muti_currency,
    diamondmarkup,
    diamondfancymarkup,
    lab_diamondmarkup,
    lab_diamondfancymarkup,
    white_label_logo,
    wl_primarycolor,
    n_fontcolor,
    lab_primary,
    lab_fontcolor,
    theme,
    tax_name,
    tax_value,
    password,
    cofirm_password,
    email,
  }
} = kycFormModel;
export const fetchData = async(mail) => {
  const userData = JSON.parse(localStorage.getItem("userData"))

  const finalData = {
      "user_id":userData.CustomerId
  }
   const promise = await axios({
    method: "POST",
    url: `${NODE_API_URL}/AUS/fetchCCModeWhiteLable`,
    headers: { "Content-Type": "application/json" },
    data: {
        CustomerId:finalData.user_id
    },
}).then(res => {
    if(res && res.data && res.data.data){
        // console.log(res.data.data,"CCMODEEEEWHITELABEL")
        // setCCModeWhiteLabelData(res.data.data)
        return {

          [muti_currency.name]: res.data.data.Currency,
          [diamondmarkup.name]: res.data.data.MarkUpNatural.toString(),
          [diamondfancymarkup.name]: res.data.data.MarkUpNaturalFancy.toString(),
          [lab_diamondmarkup.name]: res.data.data.MarkUpLab.toString(),
          [lab_diamondfancymarkup.name]: res.data.data.MarkUpLabFancy.toString(),
          [white_label_logo.name]: res.data.data.Logo,
          [wl_primarycolor.name]: res.data.data.NaturalPrimaryColor,
          [n_fontcolor.name]: res.data.data.NaturalFontColor,
          [lab_primary.name]: res.data.data.LabPrimaryColor,
          [lab_fontcolor.name]: res.data.data.LabFontColor,
          [theme.name]: [],
          [tax_name.name]: '',
          [tax_value.name]: '',
          [password.name]: '',
          [cofirm_password.name]: '',
          [email.name]: mail,
        }
    }
    else{
      return {
       
        [muti_currency.name]: 'USD',
        [diamondmarkup.name]: '00.00',
        [diamondfancymarkup.name]: '00.00',
        [lab_diamondmarkup.name]: '00.00',
        [lab_diamondfancymarkup.name]: '00.00',
        [white_label_logo.name]: '',
        [wl_primarycolor.name]: '#3699FF',
        [n_fontcolor.name]: '#ffffff',
        [lab_primary.name]: '#00c897',
        [lab_fontcolor.name]: '#ffffff',
        [password.name]: '',
        [theme.name]: [],
        [tax_name.name]: '',
        [tax_value.name]: '',
        [cofirm_password.name]: '',
        [email.name]: mail,
      }
    }
})
  // console.log(promise,"PROMISEE")
  return promise
}

