/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from "react";
import axios from "axios"
import {
    CcmodeRequest,
    CcmodeRequested,
    CcmodeRejected,
    // CcmodeSetup,
    // CcmodeApproved,
    CcmodeSetupNew
} from "./"
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import {CcmodeDashboard} from "./CcmodeDashboard";
import { useLocation} from "react-router-dom";
// import CcmodeRule from "./CcmodeRule/CcmodeRule";
// import { Alert } from "react-bootstrap";
import { CCModeLogout } from "./CCModeLogOut";
import { CircularProgress } from "@material-ui/core";
import Cookies from "js-cookie"
export const Ccmode = (props) => {
    // console.log(edit,"setEdit")
    // console.log(userinfo.kyc_status,"userinfo")
    const [ccmodedata,setCCModeData] = useState()
    const [loader,setLoader] = useState(true);
    const [ccmodewhitelabeldata,setCCModeWhiteLabelData] = useState()
    const [ccmodesetting,setCCModeSetting] = useState()
    const [ccmoderules,setCCModeRules] = useState([])
    const [updaterule,setUpdateRules] = useState(false)
    const location = useLocation()
    // const [AllowLogin,setAllowLogin] = useState(1)
    useEffect(() => {
        axios({
            method: "GET",
            url: `${NODE_API_URL}/AUS/fetch-ccmode`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
        }).then(res => {
            if(res && res.data && res.data.data){
                setLoader(false)
                // console.log(res.data.data.AllowLogin,"CCMODEEEE")
                setCCModeData(res.data.data)
                // if(res.data.data.AllowLogin.data[0]){
                //     setAllowLogin(res.data.data.AllowLogin.data[0])
                // }
                // else{
                //     setAllowLogin(0)
                // }
            }
        }).catch(error => {
            setLoader(false)
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
        axios({
            method: "GET",
            url: `${NODE_API_URL}/AUS/fetch-ccmode-white-lable`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
        }).then(res => {
            if(res && res.data && res.data.data){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                setCCModeWhiteLabelData(res.data.data)
            }
        })
        .catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
        axios({
            method: "get",
            url: `${NODE_API_URL}/AUS/fetch-ccmode-setting-data`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
        }).then(res => {
            if(res && res.data && res.data.data && res.data.data.length){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                setCCModeSetting(res.data.data)
                // if(res.data.data[0].AllowLogin.data[0]){
                //     setAllowLogin(res.data.data[0].AllowLogin.data[0])
                // }
            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    },[])
    useEffect(() => {
        axios({
            method: "get",
            url: `${NODE_API_URL}/AUS/fetch-ccmode-rules`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
        }).then(res => {
            if(res && res.data && res.data.data && res.data.data.length){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                setCCModeRules(res.data.data)
            }
            else{
                setCCModeRules([])
            }
        }) .catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          }) 
    },[updaterule])
    // console.log(ccmoderules,"ccmoderulesccmoderules")
    return (
        <div className="container-fluid">
            {loader?<div className="bg-white d-flex align-items-center justify-content-center container rounded" style={{height:"80vh"}}>
                    <div className="text-center">
                        <CircularProgress color="secondary" />
                    </div>
                </div>: <>
                        <div className="card border-0 custom-card">
                            <div className="card-body">
                            {!ccmodedata?<CcmodeRequest language={props.language}/>:""}
                            {ccmodedata && ccmodedata.Status === 1?<CcmodeRequested language={props.language}/>:""}
                            {/* {ccmodedata && ccmodedata.Status === 2 && !ccmodewhitelabeldata?<CcmodeSetup userinfo={props.userinfo} language={props.language}/>:""} */}
                            {ccmodedata && ccmodedata.Status === 2 && !ccmodesetting?<CcmodeSetupNew userinfo={props.userinfo} language={props.language}/>:""}
                            {ccmodedata && ccmodedata.Status === 3?<CcmodeRejected language={props.language}/>:""}
                            {/* {ccmodewhitelabeldata?<CcmodeApproved userinfo={props.userinfo} language={props.language}/>:""} */}
                            {ccmodedata && ccmodedata.Status === 2 && ccmodesetting ? <CcmodeDashboard ccmodesetting={ccmodesetting} userinfo={props.userinfo} language={props.language} tabvalue={location.state} ccmoderules={ccmoderules} setUpdateRules={setUpdateRules} updaterule={updaterule}/>:""}
                            {/* <CcmodeDashboard userinfo={props.userinfo} language={props.language}/>
                            <CcmodeRule userinfo={props.userinfo} language={props.language}/> */}
                            </div >
                        </div >
                </>
            }
        </div>
    );
};
